<template>
<div class="block">
    <label for="year">Choose a year:</label>
    <div class="select is-small">
        <select :value="modelValue" name="year" id="year" @change="$emit('update:modelValue', $event.target.value)">
            <option :value="2023">2023</option>
            <option :value="2022">2022</option>
            <option :value="2021">2021</option>
            <option :value="2020">2020</option>
        </select>
    </div>
    <!-- <p>Selected: {{ modelValue }}</p> -->
</div>
</template>

<script>
export default {
    name: "SelectYear",
    props: {
        modelValue: {
            type: String,
            default: '',
            required: true
        }
    },
    data() {
        return {
            // modelValue: 2000
        }
    },
    methods: {
        
    }
}
</script>

<style>

</style>